<template>
  <div id="navbar">
    <div>
      <b-sidebar
          id="sidebar"
          bg-variant="white"
          text-variant="white"
          shadow>
        <div class="d-flex flex-column justify-content-between py-2" style="height: 90%">

          <div class="text-left px-4 mb-5">
            <router-link
                to="/"
                title="Go to Home Page"
                class="mt-5">
              <h4 class="mb-3">Home</h4>
            </router-link>
            <em class="text-dark">Choose a Hospital:</em>
            <b-dropdown
                v-model="serviceArea"
                toggle-class="text-decoration-none"
                block
                variant="link"
                menu-class="w-100"
                no-caret>
              <template #button-content>
                <div class="h5 row text-info">{{hospitalsDict[serviceArea]}}
                  <b-icon-caret-down-fill scale="0.6" />
                </div>
              </template>
              <b-dropdown-item @click="serviceArea = 'A'">Arroyo Hill Medical Center</b-dropdown-item>
              <b-dropdown-item @click="serviceArea = 'B'">Bautista Community Hospital</b-dropdown-item>
              <b-dropdown-item @click="serviceArea = 'C'">Carlton Community Hospital</b-dropdown-item>
              <b-dropdown-item @click="serviceArea = 'D'">Dawson Valley Medical Center</b-dropdown-item>
              <b-dropdown-item @click="serviceArea = 'E'">Eckersley Hospital</b-dropdown-item>
              <b-dropdown-item @click="serviceArea = 'F'">Fraley Medical Clinic</b-dropdown-item>
              <b-dropdown-item @click="serviceArea = 'G'">Grissom Memorial Hospital</b-dropdown-item>
              <b-dropdown-item @click="serviceArea = 'H'">Hornsby Medical Center</b-dropdown-item>
            </b-dropdown>
            <router-link to="/carbon-emissions" title="Go to Carbon Emissions Page">
              <h4 class="mb-3">CO<sub>2</sub> Emissions Analysis</h4>
            </router-link>
            <router-link to="/upload-data" title="Go to Upload Data Page">
              <h4 class="mb-3">Upload Data</h4>
            </router-link>

          </div>

          <a
              href="https://pages.metop.io/supplychaincalculator"
              title="Go to Metopio's website"
              target="_blank"
              class="text-white btn btn-primary mx-4 my-2 py-2 lead">
            Powered by Metopio
          </a>
        </div>
      </b-sidebar>
    </div>
    <div>

      <b-navbar
          toggleable="sm"
          type="light"
          variant="white"
          fixed="top"
          class="justify-content-between shadow"
          style="min-width:420px;">

        <b-button v-b-toggle.sidebar variant="outline-secondary">
          <span class="navbar-toggler-icon"></span>
        </b-button>
        <router-link to="/" title="Go to Home Page">
          <img
              id="logo"
              src="../assets/images/yale-logo.svg"
              alt="logo"
              class="my-auto"
              style="height:50px;width:auto;">
        </router-link>
      </b-navbar>
    </div>
  </div>

</template>

<script>
import { hospitalsDict } from '@/constants'
export default {
  name: 'Navbar',
  components: {},
  data() {
    return {
      serviceArea: 'A',
      hospitalsDict,
    }
  },
  watch: {
    serviceArea() {
      this.$emit('update-service-area', this.serviceArea)
    },
  },
}

</script>

<style lang="scss">
#navbar {
  #logo:hover {
    opacity: 0.6;
    filter: saturate(3);
  }

  #sidebar {
    margin-top: 65px;
    height: 100%;
  }

  a {
    text-decoration: none;
  }

}
</style>
