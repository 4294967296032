const valueDict = {
  'Annual Spend ($)': {
    name: 'Annual Spend',
    adjustedName: 'Annual Spend per adjusted patient day',
    adjustedFormat: 'x',
    format: '$,0',
    unit: '',
    adjustedUnit: 'per adjusted patient day',
    unitPerDollar: '',
  },
  'Total Greenhouse Gases (t CO2e)': {
    name: 'Greenhouse Gases',
    adjustedName: 'Greenhouse Gases per adjusted patient day',
    format: ',0',
    adjustedFormat: 'x',
    unit: 'tCO2e',
    adjustedUnit: 'tCO2e per adjusted patient day',
    unitPerDollar: 'kg CO2e per $1',

  },
  'Total Water Withdrawals (Tgal)': {
    name: 'Water Withdrawals',
    adjustedName: 'Water Withdrawals per adjusted patient day',
    format: ',0',
    adjustedFormat: 'x',
    unit: 'Tgal',
    adjustedUnit: 'Tgal per adjusted patient day',
    unitPerDollar: 'gal per $1',
  },
  'Total PM2.5 (mg)': {
    name: 'PM2.5',
    adjustedName: 'PM2.5 per adjusted patient day',
    format: ',0',
    adjustedFormat: 'x',
    unit: 'mg',
    adjustedUnit: 'mg per adjusted patient day',
    unitPerDollar: 'mcg per $1',
  },
}

const multipliers = {
  'Arroyo Hill Medical Center': 128933,
  'Bautista Community Hospital': 53316,
  'Carlton Community Hospital': 45051,
  'Dawson Valley Medical Center': 77114,
  'Eckersley Hospital': 102039,
  'Fraley Medical Clinic': 525005,
  'Grissom Memorial Hospital': 464340,
  'Hornsby Medical Center': 90744,
  average: 185817.75,
}
const chartColors = [
  '#5da473',
  '#95c885',
  '#4c6a99',
  '#aa74b0',
  '#4b4f62',
  '#afb3bb',
  '#9cbbd2',
  '#9a4c7b',
  '#426e63',
  '#8bb788',
  '#073c74',
  '#6899c9',
  '#758c9d',
  '#4259a2',
  '#3b3e4e',
  '#7f5883',
]

const hospitalsDict = {
  A: 'Arroyo Hill Medical Center',
  B: 'Bautista Community Hospital',
  C: 'Carlton Community Hospital',
  D: 'Dawson Valley Medical Center',
  E: 'Eckersley Hospital',
  F: 'Fraley Medical Clinic',
  G: 'Grissom Memorial Hospital',
  H: 'Hornsby Medical Center',
}
export { chartColors, valueDict, hospitalsDict, multipliers }