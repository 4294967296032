<template>
  <div id ="home">
    <header>
      <div class="hero d-flex flex-column justify-content-center text-uppercase">
        <h1 class="text-white">
          Sustainability
        </h1>
        <h2 class="text-warning font-italic">
          Starts with you
        </h2>
      </div>
    </header>
    <div class="col col-11 mx-auto text-left mt-4">
      <div class="row align-items-center">
        <div class="col-12 col-md-7 mr-auto">
          <section>
            <h2 class="text-primary">
              What is the supply chain emissions carbon calculator?
            </h2>
            <p>
              This tool is specifically designed for health care organization accounting of emissions embodied in goods and
              services. It is based on the <a
                  href="https://ghgprotocol.org/"
                  title="Open Greenhouse Gas Protocol website in a new tab"
                  target="_blank">Greenhouse Gas Protocol</a>
              framework, the carbon accounting standard. The latest emissions factors from the United States Environmental
              Protection Agency and relies on Environmentally Extended Economic Input Output modeling-the most common
              emissions accounting method for large complex organizations.
            </p>
          </section>
        </div>
        <div class="col-12 col-md-4 mt-4">
          <img
              src="../assets/images/home/GettyImages-1328659820.jpg"
              alt="View from wind turbine."
              style="height: auto; max-width: 100%; border-radius: 10px;">
        </div>
      </div>
      <section>
        <h2 class="text-primary">
          Why should you care?
        </h2>
        <p>
          The United States health care sector is responsible for 8.5% of national greenhouse gas emissions, as well as
          harmful air pollution including PM2.5.
          Negative health and environmental impacts stemming from health care pollution is similar in magnitude to harm due
          to medical errors, and mitigating harm is a duty for health care organizations and care providers.
          Approximately 80% of health sector emissions stem from the supply chain, making it a critical area for carbon
          accounting and strategic management.
          As health care organizations around the world are committing to decarbonization in accordance with science-based
          targets and timelines, it is essential to have standardized, reliable, open, and easy-to-use measurement tools.
        </p>
      </section>
      <div class="row align-items-center">
        <div class="col-12 col-md-7 mr-auto">
          <section>
            <h2 class="text-primary">
              How to use the calculator?
            </h2>
            <p>
              This is a spend- and volume-based tool.
              Using either our template or your own classification system of expenditures, you can upload your annual spend
              information.
              By synching your health care organization’s procurement spreadsheet with this tool, total health care
              organization supply chain emissions will be calculated and results can be used for external reporting as well
              as organizational decarbonization goal-setting. Resolution output by procurement category is possible, and
              depends on resolution of inputted data. Greater resolution can be used to guide internal strategic management.
            </p>
            <p>
              It is recommended that one report be run for each hospital within a health system, to better understand
              variability.
            </p>
            <p>
              No data are captured by this tool; the data are yours. Any information you upload will not be saved nor will
              it be shared or sold to any other organization.
            </p>
          </section>
          <b-card>
            <div class="d-flex align-items-center justify-content-between mx-2">
              <div>
                View our detailed guide on how to use the carbon calculator here
              </div>
              <a
                  class="ml-3"
                  href="/assets/files/Supply_Chain_Emissions_Carbon_Calculator.pdf"
                  download
              >
                <b-button variant="primary">
                
                  Download guide <b-icon icon="download" class="ml-1" />
                </b-button>
              </a>
            </div>
          </b-card>

          <section>
            <h2 class="text-primary">
              Who can use the supply chain carbon calculator?
            </h2>
            <p>
              This tool is designed for health care organization sustainability managers or procurement officers with access to spend data.
            </p>
          </section>
        </div>

        <div class="col-12 col-md-4 my-auto">
          <img 
              src="../assets/images/home/GettyImages-1341490074.jpg"
              alt="Patient at hospital."
              style="height: auto; max-width: 100%; border-radius: 10px;"
          >
        </div>
      </div>

      <section>
        <h2 class="text-primary">
          The team
        </h2>
        <p>
          This tool is developed by academics from the <a
              href="https://ysph.yale.edu/yale-center-on-climate-change-and-health/"
              title="Open the Yale Center on Climate Change and Health webpage in a new tab"
              target="_blank">Yale Center on Climate Change and Health</a> and <a
                  href="https://cee.northeastern.edu/"
                  title="Open Northeastern University webpage in a new tab"
                  target="_blank">Northeastern University Department of Civil and Environmental Engineering</a>, global scientific leaders in health care sustainability measures and strategic management. It is based on the research measuring the <a
                      href="https://www.google.com/search?q=eckelman+health+affairs&oq=eck&aqs=chrome.1.69i57j69i59j46i20i131i263i433i512j46i433i512j0i433i512j46i175i199i512j0i512j0i433i512j0i10i512j46i175i199i512.4130j0j4&sourceid=chrome&ie=UTF-8"
                      target="_blank"
                      title="View scientific articles in Google in a new tab">U.S. health care sector</a>, the global health care sector with the <a
                          href="https://www.lancetcountdown.org"
                          target="_blank"
                          title="Open the Lancet Countdown in a new tab">Lancet Countdown on Health and Climate Change</a>, and our experience developing an integrated supply chain emissions calculator for <a
                              href="https://www.england.nhs.uk/greenernhs/"
                              target="_blank"
                              title="Open the NHS Webpage in a new tab">Greener NHS Programme</a>, the largest health system in the world.
        </p>
        <p>
          The web platform is developed by <a href="https://pages.metop.io/supplychaincalculator" target="_blank">Metopio</a>. Metopio’s software uses data and visualizations to reveal valuable, interconnected factors that influence health in different locations. Metopio’s tools are built so that anyone – regardless of their data science experience – can use data and analytics to guide their work. 
        </p>
      </section>

      <section>
        <h2 class="text-primary">
          Contact us
        </h2>
        <p>
          If you are interested in participating or learning more, please send your name and institution to <span id="email" /> 
        </p>
      </section>
    </div>
  </div>
</template>

<script>
import Supply_Chain_Emissions_Carbon_Calculator from '../assets/files/Supply_Chain_Emissions_Carbon_Calculator.pdf'
export default {
  name: 'Home',
  data() {
    return {
      email: {
        name: 'contact',
        domain: 'healthcareghg',
        tld: 'org',
      },
      
    }
  },
  mounted() {
    let html = document.getElementById('email')
    html.innerHTML = `<a href="mailto:${this.email.name}@${this.email.domain}.${this.email.tld}">${this.email.name}@${this.email.domain}.${this.email.tld}</a>`
  },

  methods: {
  },
}

</script>

<style scoped lang="scss">
header {
  background-image: url("../assets/images/home/GettyImages-1130655067.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size:cover;
  height: 85vh;
}

.hero {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.35);
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 1.8rem;
}

section {
  margin-top: 5%;
}

a,
#email {
  font-weight: bold;
}
</style>