<template>
  <div id="app">
    <NavBar class="d-print-none" @update-service-area="updateServiceArea"/>
    <div class="main-view">
      <router-view :hospital="hospital"/>
    </div>
  
  </div>
</template>

<script>
import NavBar from './components/Navbar.vue'
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      hospital: {
        value: 'A',
      },
    }
  },
  methods: {
    updateServiceArea(hospital) {
      this.hospital.value = hospital
    }, 
  },
}
</script>

<style lang="scss">
#app {
  font-family: YaleNew;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 424px;
  margin-bottom: 66px;

  font-size: large;
}
.main-view {
  margin-top: 65px;
}
</style>
